import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { removeEmpty, route } from "@/helpers";
import { format } from "@/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "@/lib/Translator";
import Dialog from "../../../../../js/react/components/general/Dialog";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { error, notify, queueNotification, warning } from "@/react/components/general/notifications";
import Spinner from "../../../../../js/react/components/general/Spinner";
import KvkDocument from "./KvkDocument";
import KvkTree from "./KvkTree";
import Tippy from "@tippyjs/react";

export default function CompanyKvkData({
	companyId,
	mainCompany,
	changeStatutoryCompanyFields,
	statutoryCompany,
	onModalClose,
}) {
	const [loading, setLoading] = useState(false);
	const [pdfloading, setPdfLoading] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [printKvkResultOpen, setPrintKvkResultOpen] = useState(false);
	const [includePdfInDossier, setIncludePdfInDossier] = useState(false);
	const [kvkData, setKvkData] = useState(null);
	const [company, setCompany] = useState(null);
	const [notificationSbiCodes, setNotificationSbiCodes] = useState(null);
	const [dossierDirectors, setDossierDirectors] = useState([]);
	const [mainKvkNumber, setMainKvkNumber] = useState(null);
	const [filename, setFilename] = useState("");
	const [currentKvkDocument, setCurrentKvkDocument] = useState(null);

	const getDocumentByKvkNumber = useCallback(
		kvkNumber => {
			if (kvkData && kvkData.documents && kvkData.documents.length) {
				return kvkData.documents.find(document => document.kvk_number === kvkNumber);
			}
		},
		[kvkData],
	);

	const fetchKvkData = useCallback(
		async (forceNew = false) => {
			setDialogOpen(true);
			setLoading(true);

			const requestData = { company: companyId };

			if (forceNew) {
				requestData.force_new = true;
			}

			try {
				const { data } = await axios.get(route("crm.companies.fetch-kvk-data", requestData));
				if (data && Object.keys(data).length && Object.keys(data.kvk_data).length) {
					setKvkData(data.kvk_data);
					setCompany(data.company);
					setNotificationSbiCodes(data.notification_sbi_codes);
					setDossierDirectors(data.dossier_directors);

					// if forcenew was requested, or data was retrieved for the first time set address in company form
					if (
						(forceNew ||
							(data &&
								data.kvk_data &&
								data.kvk_data.datetime &&
								new Date() - new Date(data.kvk_data.datetime) < 5000)) &&
						changeStatutoryCompanyFields &&
						statutoryCompany
					) {
						const statutoryDocument = data.kvk_data.documents.find(
							document => document.kvk_number === data.kvk_data.tree.kvk_number,
						);

						const statutoryFieldsToUpdate = {
							name: statutoryDocument?.statutory_name,
							address: statutoryDocument?.statutory_address,
							legal_form: statutoryDocument?.statutory_legal_form,
						};
						const newStatutoryFields = removeEmpty(statutoryFieldsToUpdate);
						if (statutoryDocument && !isEmpty(newStatutoryFields)) {
							changeStatutoryCompanyFields("statutory_company", {
								...statutoryCompany,
								...newStatutoryFields,
							});
						}
					}

					setLoading(false);
				} else {
					notify("error", uctrans("companies.messages.kvk_data_not_found"), null);
					setLoading(false);
					setDialogOpen(false);
					setKvkData(null);
				}
			} catch (error) {
				console.error(error);

				notify("error", uctrans("companies.messages.kvk_data_not_found"), null);
				setLoading(false);
				setDialogOpen(false);
				setKvkData(null);
			}
		},
		[changeStatutoryCompanyFields, companyId, statutoryCompany],
	);

	const showDocument = useCallback(
		kvkNumber => {
			if (kvkData && kvkData.documents && kvkData.documents.length) {
				const foundDocument = getDocumentByKvkNumber(kvkNumber);
				if (foundDocument) {
					setCurrentKvkDocument(foundDocument);
				}
			}
		},
		[getDocumentByKvkNumber, kvkData],
	);

	const onClose = useCallback(() => {
		setDialogOpen(false);
		if (onModalClose) {
			onModalClose();
		}
	}, [onModalClose]);

	const generatePdf = async () => {
		setPdfLoading(true);
		if (filename === "") {
			error(uctrans("statutory_companies.kvk.pdf_name_required"));
			setPdfLoading(false);
			return;
		}

		const result = await axios.post(route("crm.kvk.generate-pdf"), {
			pdfData: {
				tree: kvkData.tree,
				document: currentKvkDocument,
				datetime: kvkData.datetime,
				filename,
				saveInDossier: includePdfInDossier,
				companyId,
			},
		});

		if (result && result.data.success) {
			setPdfLoading(false);
			setPrintKvkResultOpen(false);

			if (includePdfInDossier) {
				queueNotification(
					"success",
					includePdfInDossier
						? uctrans("statutory_companies.kvk.pdf_export_dossier_succeeded")
						: uctrans("statutory_companies.kvk.pdf_export_succeeded"),
					null,
				);
				window.location.reload();
			} else {
				Nav.go(
					route("crm.kvk.download-pdf", {
						download_key: result.data.download_key,
					}),
				);
			}
		}
	};

	const exportPdf = () => {
		if (company !== null) {
			setFilename(
				company.company_name
					.replace(/[/\\?%*:|"<>]/g, "-")
					.split(" ")
					.join("_"),
			);
		}
		setPrintKvkResultOpen(true);
	};

	useEffect(() => {
		if (kvkData && kvkData.tree) {
			setMainKvkNumber(kvkData.tree.kvk_number);
			showDocument(kvkData.tree.kvk_number);
		}
	}, [kvkData, showDocument]);

	useEffect(() => {
		const registration = kvkData?.tree?.registration ?? null;
		if (registration === null) {
			return;
		}

		const rawEndDate = registration?.end_date;
		if (rawEndDate === null) {
			return;
		}

		warning(uctrans("statutory_companies.kvk.registration.expired"));
	}, [kvkData]);

	return (
		<div>
			<a className="button button-tertiary text-center w-64" onClick={() => fetchKvkData()}>
				{uctrans("companies.fetch_kvk_data")}
			</a>
			<Dialog width={currentKvkDocument !== null ? 1600 : 900} isOpen={dialogOpen} onClose={onClose}>
				{!!loading && <Spinner width={25} />}
				{!loading && kvkData ? (
					<div className="form-full">
						<div className="flex w-full justify-between">
							<h1>{uctrans("statutory_companies.kvk_structure")}</h1>
							<div>
								{kvkData.datetime && (
									<span className="flex justify-start">
										<span style={{ width: "110px" }} className="text-sm font-bold">
											{uctrans("companies.messages.last_update_date")}
										</span>
										<span className="text-sm ml-1">{format(kvkData.datetime, "dd-MM-y HH:mm:ss")}</span>
									</span>
								)}

								<IconButton
									onClick={() => exportPdf()}
									content={uctrans("companies.messages.export_pdf")}
									icon="download-outline"
									disabled={loading}
									primary
								/>
							</div>
						</div>

						<div className="flex w-full justify-between mt-2">
							<div className={`kvk-tree mr-10 ${currentKvkDocument !== null ? "w-1/2 border-r" : "w-full"}`}>
								<KvkTree
									treeData={kvkData.tree}
									showDocument={showDocument}
									currentSelectedKvkNumber={currentKvkDocument ? currentKvkDocument.kvk_number : null}
									dossierDirectors={dossierDirectors}
								/>
							</div>
							{currentKvkDocument !== null && (
								<div className="w-1/2">
									<KvkDocument
										documentData={currentKvkDocument}
										showDocument={showDocument}
										hideDocument={() => setCurrentKvkDocument(null)}
										showEstablishmentErrors={mainKvkNumber === currentKvkDocument.kvk_number}
										company={company}
										notificationSbiCodes={notificationSbiCodes}
										registration={kvkData.tree.registration}
									/>
								</div>
							)}
						</div>
					</div>
				) : null}

				<Dialog width={600} isOpen={printKvkResultOpen} onClose={() => setPrintKvkResultOpen(false)}>
					<h1>{uctrans("statutory_companies.kvk.kvk_print_kvk_data")}</h1>
					<div className="form-full">
						<div className="form-2-3">
							<LabeledTextInput
								name="filename"
								required
								value={filename}
								onChange={e => setFilename(e.target.value)}
								label="statutory_companies.kvk.pdf_name"
							/>
						</div>
					</div>
					<div className="form-full">
						<Tippy
							placement="auto"
							arrow
							animation="perspective"
							duration="400"
							disabled={mainCompany}
							content={uctrans("statutory_companies.kvk.pdf_explanation")}>
							<div>
								<Checkmark
									onChange={e => setIncludePdfInDossier(e.target.checked)}
									name="include_in_dossier"
									disabled={!mainCompany}
									checked={includePdfInDossier}
									label={uctrans("statutory_companies.kvk.include_pdf_in_dossier")}
								/>
							</div>
						</Tippy>
					</div>
					<div className="form-full">
						<button
							onClick={() => generatePdf()}
							className={`button button-primary leading-normal ${pdfloading && "disabled"}`}>
							{uctrans("statutory_companies.kvk.generate_pdf")}
						</button>
					</div>
				</Dialog>
			</Dialog>
		</div>
	);
}

CompanyKvkData.propTypes = {
	companyId: PropTypes.number.isRequired,
	mainCompany: PropTypes.bool,
	changeStatutoryCompanyFields: PropTypes.func,
	statutoryCompany: PropTypes.object,
	onModalClose: PropTypes.func,
};
