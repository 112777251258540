import TextEditorComponent from "@crm/react/components/forms/textEditor/TextEditorComponent";
import axios from "axios";
import PropTypes from "prop-types";
import { map } from "lodash";
import { useCallback, useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";

export default function GenerateCooperationAgreement({ companyId }) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [sendSOKEmailDialog, setSendSOKEmailDialog] = useState(false);
	const [showSOKPreview, setShowSOKPreview] = useState(false);
	const [loading, setLoading] = useState(false);
	const [checkData, setCheckData] = useState(null);
	const [formValues, setFormValues] = useState([]);
	const [extraMessage, setExtraMessage] = useState("");

	const genericHandler = useGenericHandler(setFormValues);

	const fetchCooperationAgreementData = useCallback(async () => {
		const loader = createLoader(uctrans("dossiers.cooperation_agreements.messages.check_data"));
		setDialogOpen(true);
		setLoading(true);

		// Do stuff
		try {
			const { data } = await axios.get(
				route("crm.dossier-settings.cooperation-agreements.check", { company: companyId }),
			);
			setCheckData(data);

			const selectedDirectorIds = map(data.directors, d => d.id.toString());
			genericHandler(selectedDirectorIds, "directors");

			loader.success(uctrans("dossiers.cooperation_agreements.messages.check_complete"));
		} catch (error) {
			setDialogOpen(false);
			loader.failure(error.response.data.errors.join("<br /><br />"));
		}

		setLoading(false);
	}, [companyId, genericHandler]);

	const exportPdf = () => {
		if (typeof formValues.directors === "undefined" || formValues.directors.length === 0) {
			error(uctrans("dossiers.cooperation_agreements.messages.check_no_directors_selected"));

			return;
		}

		Nav.go(
			route("crm.dossier-settings.cooperation-agreements.pdf", {
				company: companyId,
				directors: formValues.directors,
			}),
		);

		setDialogOpen(false);
	};

	return (
		<div>
			<a className="button button-tertiary text-center w-64" onClick={() => fetchCooperationAgreementData()}>
				{uctrans("dossiers.cooperation_agreements.messages.generate_sok_as_pdf")}
			</a>
			<Dialog width={900} isOpen={dialogOpen} onClose={() => setDialogOpen(false)}>
				{!!loading && <Spinner width={25} />}
				{!loading && checkData ? (
					<div className="form-full">
						<div className="flex w-full justify-between mt-2">
							<h1>{uctrans("dossiers.cooperation_agreements.messages.generate_sok_as_pdf")}</h1>
						</div>

						<h2>{uctrans("dossiers.cooperation_agreements.messages.directors")}</h2>
						<CheckmarkGroup
							name="directors"
							initialChecked={formValues.directors}
							onChange={value => genericHandler(value, "directors")}>
							{map(checkData.directors, director => (
								<Checkmark key={director.id} value={director.id} name={director.id} label={director.full_name} />
							))}
						</CheckmarkGroup>

						<IconButton
							onClick={() => exportPdf()}
							content={uctrans("dossiers.cooperation_agreements.messages.export_pdf")}
							icon="download-outline"
							disabled={loading}
							primary
						/>
						<IconButton
							onClick={() => setSendSOKEmailDialog(true)}
							content={uctrans("dossiers.cooperation_agreements.messages.send_sok_as_email")}
							icon="email-outline"
							disabled={loading}
							className="ml-5"
							primary
						/>
					</div>
				) : null}
			</Dialog>

			<Dialog width={550} isOpen={sendSOKEmailDialog} onClose={() => setSendSOKEmailDialog(false)}>
				<div className="form-full">
					<div className="flex w-full justify-between mt-2">
						<h1>{uctrans("dossiers.cooperation_agreements.messages.send_sok_as_email_dialog_title")}</h1>
					</div>

					<span>{uctrans("dossiers.cooperation_agreements.messages.send_sok_as_email_dialog_description")}</span>

					<div className="mt-5">
						<AjaxForm
							method="POST"
							data={{ directors: formValues.directors, extra_message: extraMessage }}
							submitUrl={route("crm.dossier-settings.cooperation-agreements.send-email", { company: companyId })}
							loaderText={uctrans(
								"general.:item_is_being_mailed",
								{},
								{ item: "dossiers.cooperation_agreements.singular" },
							)}
							successText={uctrans("dossiers.cooperation_agreements.messages.succesfully_sent_sok")}
							onSuccess={() => {
								setSendSOKEmailDialog(false);
								setDialogOpen(false);
							}}>
							<label>{uctrans("dossiers.cooperation_agreements.fields.extra_message")}</label>
							<TextEditorComponent value={extraMessage} onChange={setExtraMessage} />
							<IconButton
								onClick={() => setShowSOKPreview(true)}
								content={uctrans("dossiers.cooperation_agreements.messages.show_preview")}
								icon="navigation-2-outline"
							/>

							<button type="submit" className="button button-primary ml-5">
								<div className="flex-button">
									<EvaIcon type="email-outline" className="mr-2" fill="#25282e" height="16" width="16" />
									<span className="icon-text">{uctrans("dossiers.cooperation_agreements.messages.send")}</span>
								</div>
							</button>
						</AjaxForm>
					</div>
				</div>
			</Dialog>

			<Dialog isOpen={showSOKPreview} onClose={() => setShowSOKPreview(false)} shouldCloseOnOverlayClick width={1000}>
				<iframe
					id="mail_iframe"
					width="100%"
					height="700px"
					src={route("crm.dossier-settings.cooperation-agreements.email-preview", {
						company: companyId,
						extra_message: btoa(extraMessage),
					})}
				/>
			</Dialog>
		</div>
	);
}

GenerateCooperationAgreement.propTypes = {
	companyId: PropTypes.number.isRequired,
};
