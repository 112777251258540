import PropTypes from "prop-types";
import { route } from "@/helpers";
import { uctrans } from "@/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import KvkEstablishment from "./KvkEstablishment";
import { format } from "@/lib/Date";

export default function KvkDocument({
	documentData,
	showDocument,
	hideDocument,
	company,
	showEstablishmentErrors,
	notificationSbiCodes,
	registration,
}) {
	const currentEstablishment = documentData.establishments
		? documentData.establishments.find(
				establishment => establishment.kvk_location_number === company.kvk_location_number,
		  )
		: null;

	const otherSortedEstablishments = documentData.establishments
		? documentData.establishments
				.filter(establishment => establishment.kvk_location_number !== company.kvk_location_number)
				.sort((a, b) => a.city_name.localeCompare(b.city_name))
		: null;

	return (
		<div className="w-full">
			<div className="flex w-full justify-between">
				<h4>{uctrans("statutory_companies.kvk_data")} </h4>
				{hideDocument && (
					<a className="link" onClick={() => hideDocument()}>
						{uctrans("statutory_companies.close_document")}
					</a>
				)}
			</div>
			<div className="w-full">
				<div className="establishment-row">
					<ul>
						<li className="flex">
							<span style={{ width: "150px" }} className="text-sm font-bold">
								{uctrans("statutory_companies.statutory_name")}
							</span>
							<span className="text-sm">{documentData.statutory_name ? documentData.statutory_name : "-"}</span>
						</li>
						<li className="flex">
							<span style={{ width: "150px" }} className="text-sm font-bold">
								{uctrans("statutory_companies.fields.kvk_number")}
							</span>
							{documentData.kvk_number ? (
								showDocument ? (
									<a className="text-sm link" onClick={() => showDocument(documentData.kvk_number)}>
										{documentData.kvk_number}
									</a>
								) : (
									<span className="text-sm">{documentData.kvk_number}</span>
								)
							) : null}
						</li>
						{documentData.statutory_legal_form ? (
							<li className="flex">
								<span style={{ width: "150px" }} className="text-sm font-bold">
									{uctrans("statutory_companies.statutory_legal_form")}
								</span>
								<span className="text-sm">{documentData.statutory_legal_form}</span>
							</li>
						) : null}
						{documentData.statutory_seat ? (
							<li className="flex">
								<span style={{ width: "150px" }} className="text-sm font-bold">
									{uctrans("statutory_companies.statutory_seat")}
								</span>
								<span className="text-sm">{documentData.statutory_seat}</span>
							</li>
						) : null}
						{documentData.sbi_codes && documentData.sbi_codes.length ? (
							<li className="flex">
								<span style={{ width: "150px" }} className="text-sm font-bold">
									{uctrans("statutory_companies.activities")}
								</span>
								<ul>
									{documentData.sbi_codes.map((sbiCode, index) => (
										<li key={index} className="text-sm">
											{sbiCode}
										</li>
									))}
								</ul>
							</li>
						) : null}
						{registration?.start_date ? (
							<li className="flex">
								<span style={{ width: "150px" }} className="text-sm font-bold">
									{uctrans("statutory_companies.kvk.registration.start_date")}
								</span>
								<span className="text-sm">{format(registration.start_date, "dd-MM-yyyy")}</span>
							</li>
						) : null}
						{registration?.end_date ? (
							<li className="flex text-red">
								<span style={{ width: "150px" }} className="text-sm font-bold">
									{uctrans("statutory_companies.kvk.registration.end_date")}
								</span>
								<span className="text-sm">{format(registration.end_date, "dd-MM-yyyy")}</span>
							</li>
						) : null}
					</ul>

					{documentData.owners && documentData.owners.length ? (
						<>
							<h4 className="mt-4">{uctrans("statutory_companies.owners.plural")} </h4>
							<ul>
								{documentData.owners.map((owner, index) => (
									<li key={index} className="w-full mt-2 border p-2 bg-grey-lighter">
										<h6>{owner.function || "-"}</h6>
										<ul>
											<li className="flex">
												<span style={{ width: "150px" }} className="text-sm font-bold">
													{uctrans("statutory_companies.owners.name")}
												</span>
												<span className="text-sm">{owner.name || "-"}</span>
											</li>
											{owner.visiting_address ? (
												<li className="flex">
													<span style={{ width: "150px" }} className="text-sm font-bold">
														{uctrans("statutory_companies.owners.visiting_address")}
													</span>
													<span className="text-sm">{owner.visiting_address}</span>
												</li>
											) : null}
											{owner.kvk_number ? (
												<li className="flex">
													<span style={{ width: "150px" }} className=" text-sm font-bold">
														{uctrans("statutory_companies.fields.kvk_number")}
													</span>
													<a className="text-sm link" onClick={() => showDocument(owner.kvk_number)}>
														{owner.kvk_number}
													</a>
												</li>
											) : null}
											{owner.date_of_birth ? (
												<li className="flex">
													<span style={{ width: "150px" }} className=" text-sm font-bold">
														{uctrans("statutory_companies.owners.date_of_birth")}
													</span>
													<span className="text-sm">{`${owner.date_of_birth.substring(
														6,
														8,
													)}-${owner.date_of_birth.substring(4, 6)}-${owner.date_of_birth.substring(0, 4)}`}</span>
												</li>
											) : null}
										</ul>
									</li>
								))}
							</ul>
						</>
					) : null}
				</div>

				{documentData.establishments.length ? (
					<>
						<h4 className="establishment-row mt-4">
							{uctrans(
								`statutory_companies.establishments.${documentData.establishments.length > 1 ? "plural" : "singular"}`,
							)}{" "}
						</h4>
						<ul>
							{currentEstablishment ? (
								<KvkEstablishment
									highlightEstablishment
									establishment={currentEstablishment}
									notificationSbiCodes={notificationSbiCodes}
								/>
							) : showEstablishmentErrors ? (
								<span className="text-red">
									<EvaIcon type="alert-triangle-outline" height="18" width="18" fill="#E3342F" />{" "}
									<span
										dangerouslySetInnerHTML={{
											__html: uctrans("statutory_companies.establishments.could_not_find_current_establishment", {
												intermediary: company.company_name,
												tp_number: company.tp_number,
												href: route(
													`crm.${company.intermediary ? "intermediaries" : "organisations"}.edit-administrative`,
													company.id,
												),
											}),
										}}
									/>
								</span>
							) : null}

							{otherSortedEstablishments &&
								otherSortedEstablishments.map((establishment, index) => (
									<KvkEstablishment
										key={index}
										establishment={establishment}
										notificationSbiCodes={notificationSbiCodes}
									/>
								))}
						</ul>
					</>
				) : null}
			</div>
		</div>
	);
}

KvkDocument.propTypes = {
	documentData: PropTypes.object,
	hideDocument: PropTypes.func,
	showDocument: PropTypes.func,
	company: PropTypes.object,
	showEstablishmentErrors: PropTypes.bool,
	notificationSbiCodes: PropTypes.array,
	registration: PropTypes.shape({
		start_date: PropTypes.string,
		end_date: PropTypes.string,
	}),
};
