import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { useState, useEffect, useCallback, useRef } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import { uctrans } from "../../../../../../js/lib/Translator";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import TextEditor from "../../forms/textEditor/TextEditor";
import CadastreReport from "./components/CadastreReport";
import DefaultReport from "./components/DefaultReport";

export default function MarketScanReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [staticData] = useState(window.data.staticData ? cloneDeep(window.data.staticData) : []);
	const formRef = useRef(null);
	const [result, setResult] = useState(null);
	const [analysis, setAnalysis] = useState("");
	const [loading, setLoading] = useState(false);

	const [filterData, setFilterData] = useState(null);

	const genericHandler = useGenericHandler(setFormValues);
	const selectHandler = useSelectHandler(setFormValues);

	const filterChange = useCallback(async () => {
		try {
			const result = await axios.post(route("crm.report.market-scan.filter-change"), {
				...formValues,
			});
			setFilterData(result.data);

			if (result.data.cadastre_months && formValues.month == null && formValues.cadastre_period_type === "month") {
				const month =
					result.data.cadastre_months && formValues.month == null ? result.data.cadastre_months[0].value : null;

				const year =
					result.data.cadastre_years && formValues.year == null
						? result.data.cadastre_years[0].value
						: formValues.year || null;

				setFormValues({
					...formValues,
					month,
					year,
				});
			} else if (
				result.data.cadastre_quarters &&
				formValues.quarter == null &&
				formValues.cadastre_period_type === "quarter"
			) {
				const quarter =
					result.data.cadastre_quarters && formValues.quarter == null ? result.data.cadastre_quarters[0].value : null;
				const year =
					result.data.cadastre_years && formValues.year == null
						? result.data.cadastre_years[0].value
						: formValues.year || null;
				setFormValues({
					...formValues,
					quarter,
					year,
				});
			} else if (result.data.cadastre_years && formValues.year == null && formValues.cadastre_period_type === "year") {
				const year =
					result.data.cadastre_years && formValues.year == null
						? result.data.cadastre_years[0].value
						: formValues.year || null;

				setFormValues({
					...formValues,
					year,
				});
			}
		} catch (e) {
			console.error(e);
		}
	}, [formValues]);

	useEffect(() => {
		filterChange();
	}, [formValues, filterChange]);

	const generatePdf = async () => {
		setLoading(true);
		const result = await axios.post(route("crm.report.market-scan.generate-pdf"), {
			...formValues,
			analysis,
		});

		if (result && result.data.success) {
			setLoading(false);
			Nav.go(
				route("crm.report.market-scan.download-pdf", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	const onSuccess = response => {
		if (response.data) {
			setLoading(false);
			setResult(response.data);
		}
	};

	const onError = error => {
		console.error(error);
		setLoading(false);
	};

	const getComponent = result => {
		switch (result.type) {
			case "lenders":
			case "new_refinancing":
			case "starter_transfer":
			case "type_construction":
			case "energy_label":
			case "nature_collateral":
				return <CadastreReport data={result} />;
			case "house_type":
			case "construction_year_house":
			case "woz_value":
			case "woz_value_development":
			case "age":
			case "relocation":
			case "family_composition":
			case "family_size":
			case "education":
			case "income":
			case "loans":
			case "savings":
			case "stock_investments":
			case "house_ownership":
				return <DefaultReport data={result} />;
		}
		return null;
	};

	const showPeriods = () =>
		formValues.report_type === "lenders" ||
		formValues.report_type === "new_refinancing" ||
		formValues.report_type === "starter_transfer" ||
		formValues.report_type === "type_construction" ||
		formValues.report_type === "nature_collateral" ||
		formValues.action === "pdf";

	const showGeoDateSelect = () =>
		formValues.report_type === "house_type" ||
		formValues.report_type === "construction_year_house" ||
		formValues.report_type === "woz_value" ||
		formValues.report_type === "woz_value_development" ||
		formValues.report_type === "age" ||
		formValues.report_type === "family_composition" ||
		formValues.report_type === "family_size" ||
		formValues.report_type === "house_ownership" ||
		formValues.report_type === "education" ||
		formValues.report_type === "income" ||
		formValues.report_type === "loans" ||
		formValues.report_type === "savings" ||
		formValues.report_type === "stock_investments" ||
		formValues.action === "pdf";

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.report.market-scan.data")}
			onSubmit={() => setLoading(true)}
			onSuccess={onSuccess}
			onError={onError}
			data={{ ...formValues }}
			ref={formRef}
			useFlashMessage>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.market_scan")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-1/6">
					<label htmlFor="post">{uctrans("report.action")}</label>
					<RadioGroup
						name="action"
						value={formValues.action}
						onChange={postValue => genericHandler(postValue, "action")}>
						<Radio label={uctrans("report.market_scan.generate_pdf")} value="pdf" />
						<Radio label={uctrans("report.market_scan.show_part")} value="part" />
					</RadioGroup>
				</div>

				<div className="w-3/4 flex flex-row">
					<div className="w-full mr-2">
						<div className="flex flex-row">
							<div className="w-2/3 mr-2">
								{formValues.action === "pdf" ? (
									<>
										<label htmlFor="pdf_parts">
											<Translate content="report.intermediaries.pdf_parts" />
										</label>
										<div className="flex flex-row">
											<CheckmarkGroup
												name="pdf_parts"
												initialChecked={formValues.pdf_parts}
												onChange={pdfParts => genericHandler(pdfParts, "pdf_parts")}>
												{map(formInfo.checkBoxOptions, option => (
													<Checkmark
														key={option.value}
														value={option.value}
														name={option.value}
														label={option.label}
														className="mr-3"
													/>
												))}
											</CheckmarkGroup>
										</div>
									</>
								) : (
									<>
										<label htmlFor="report_type">
											<Translate content="report.type_report" />
										</label>
										<Select
											isClearable={false}
											placeholder={uctrans("general.select_:item", {}, { item: "report.type_report" })}
											value={staticData.report_types.filter(({ value }) => `${value}` === `${formValues.report_type}`)}
											options={formInfo.marketScanReportTypes}
											name="report_type"
											onChange={selectHandler}
										/>{" "}
									</>
								)}
							</div>
							{formValues.action === "pdf" && (
								<div className="w-1/3 mr-2">
									<label htmlFor="selected_company">
										<Translate content="report.market_scan.pdf_for_intermediary" />
									</label>
									<SingleSelectAutoComplete
										name="selected_company"
										dataSource={route("crm.intermediaries.search-active").toString()}
										item={formValues.selected_company}
										initialItem={formValues.selected_company}
										isClearable
										onChange={value => genericHandler(value ? value.value : null, "selected_company")}
										placeholder={uctrans("general.type_to_select_:item", {
											item: uctrans("intermediaries.singular"),
										})}
										async
									/>
								</div>
							)}
						</div>
						<div className="flex flex-row">
							<div className="w-1/3 mr-2">
								<label htmlFor="province">{uctrans("location.provinces.singular")}</label>
								<Select
									name="province"
									placeholder={uctrans("general.select_:item", {}, { item: "location.provinces.singular" })}
									value={formInfo.provinces.filter(({ value }) => `${value}` === `${formValues.province}`)}
									options={formInfo.provinces}
									onChange={selectHandler}
								/>
							</div>

							{!!filterData && (
								<>
									{!!filterData.townships && (
										<div className="w-1/3 mr-2">
											<label htmlFor="year_pdf">{uctrans("location.townships.singular")}</label>
											<Select
												name="township"
												placeholder={uctrans("general.select_:item", {}, { item: "location.townships.singular" })}
												value={filterData.townships.filter(({ value }) => `${value}` === `${formValues.township}`)}
												options={filterData.townships}
												onChange={selectHandler}
											/>
										</div>
									)}
									{!!filterData.zipcodes && (
										<>
											<div className="w-1/3 mr-2">
												<label htmlFor="zipcode_from">{uctrans("location.zipcode_areas.fields.zipcode_from")}</label>
												<Select
													name="zipcode_from"
													placeholder={uctrans("general.select_:item", {}, { item: "location.zipcode_areas.singular" })}
													value={filterData.zipcodes.filter(({ value }) => `${value}` === `${formValues.zipcode_from}`)}
													options={filterData.zipcodes}
													onChange={selectHandler}
												/>
											</div>
											<div className="w-1/3">
												<label htmlFor="zipcode_from">{uctrans("location.zipcode_areas.fields.zipcode_until")}</label>
												<Select
													name="zipcode_until"
													placeholder={uctrans("general.select_:item", {}, { item: "location.zipcode_areas.singular" })}
													value={filterData.zipcodes.filter(
														({ value }) => `${value}` === `${formValues.zipcode_until}`,
													)}
													options={filterData.zipcodes}
													onChange={selectHandler}
												/>
											</div>
										</>
									)}
								</>
							)}
						</div>
						<div className="flex flex-row">
							{showPeriods() ? (
								<>
									<div className="w-1/3 mr-2">
										<label htmlFor="period_type">{uctrans("report.market_scan.cadastre_period")}</label>
										<Select
											isClearable={false}
											name="cadastre_period_type"
											placeholder={uctrans("general.select_:item", {}, { item: "report.market_scan.cadastre_period" })}
											value={formInfo.cadastrePeriodTypes.filter(
												({ value }) => `${value}` === `${formValues.cadastre_period_type}`,
											)}
											options={formInfo.cadastrePeriodTypes}
											onChange={selectHandler}
										/>
									</div>
									{!!filterData && (
										<>
											{!!filterData.cadastre_years && (
												<div className="w-1/3 mr-2">
													<label htmlFor="cadastre_years">{uctrans("report.year")}</label>
													<Select
														isClearable={false}
														name="year"
														placeholder={uctrans("general.select_:item", {}, { item: "report.year" })}
														value={filterData.cadastre_years.filter(({ value }) => `${value}` === `${formValues.year}`)}
														options={filterData.cadastre_years}
														onChange={selectHandler}
													/>
												</div>
											)}
											{!!filterData.cadastre_months && (
												<div className="w-1/3 mr-2">
													<label htmlFor="month">{uctrans("report.month")}</label>
													<Select
														isClearable={false}
														name="month"
														placeholder={uctrans("general.select_:item", {}, { item: "report.month" })}
														value={filterData.cadastre_months.filter(
															({ value }) => `${value}` === `${formValues.month}`,
														)}
														options={filterData.cadastre_months}
														onChange={selectHandler}
													/>
												</div>
											)}
											{!!filterData.cadastre_quarters && (
												<div className="w-1/3 mr-2">
													<label htmlFor="quarter">{uctrans("report.quarter")}</label>
													<Select
														isClearable={false}
														name="quarter"
														placeholder={uctrans("general.select_:item", {}, { item: "report.quarter" })}
														value={filterData.cadastre_quarters.filter(
															({ value }) => `${value}` === `${formValues.quarter}`,
														)}
														options={filterData.cadastre_quarters}
														onChange={selectHandler}
													/>
												</div>
											)}
										</>
									)}
								</>
							) : null}

							{showGeoDateSelect() && (
								<div className="w-1/3 mr-2">
									<label htmlFor="reference_date">{uctrans("report.market_scan.geodata_reference_date")}</label>
									<Select
										isClearable={false}
										name="reference_date"
										placeholder={uctrans(
											"general.select_:item",
											{},
											{ item: "report.market_scan.geodata_reference_date" },
										)}
										value={formInfo.referenceDates.filter(({ value }) => `${value}` === `${formValues.reference_date}`)}
										options={formInfo.referenceDates}
										onChange={selectHandler}
									/>
								</div>
							)}

							{formValues.action === "pdf" && (
								<div className="w-1/2 pr-2">
									<label htmlFor="add_analysis">
										<Translate content="report.add_analysis" />
									</label>
									<RadioGroup
										horizontal
										name="add_analysis"
										value={formValues.add_analysis}
										onChange={value => genericHandler(value, "add_analysis")}>
										<Radio label={uctrans("general.no")} value={0} />
										<Radio label={uctrans("general.yes")} value={1} />
									</RadioGroup>
								</div>
							)}
						</div>
						{formValues.action === "pdf" && formValues.add_analysis ? (
							<div className="mt-3">
								<TextEditor value={analysis} onChange={content => setAnalysis(content)} />
							</div>
						) : null}
						{formValues.action === "pdf" ? (
							<div className="flex flex-row">
								<IconButton
									icon="file-text-outline"
									disabled={loading}
									onClick={() => generatePdf()}
									content={uctrans("report.generate_report")}
									primary
								/>
							</div>
						) : (
							<div className="flex w-full justify-start">
								<button type="submit" className="button button-primary leading-normal">
									{uctrans("report.show_report")}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="mt-5">
				{!!loading && (
					<div className="flex justify-center">
						<Spinner width={50} />
					</div>
				)}
				{result && !loading ? getComponent(result) : null}
			</div>
		</AjaxForm>
	);
}
